import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "../forms/contactForm"

const ContactFormSection = ({ heading, subheading }) => {
  return (
      <>
      {heading && 
        <h2 className="text-2xl sm:text-5xl text-center font-medium mt-16">
          {heading}
        </h2>
      }
      {subheading && 
        <h3 className="text-base mt-8 sm:text-2xl text-center mx-auto max-w-[700px]">
          {subheading}
        </h3>
      }
      <div className="flex mt-20 mb-32 flex-row items-center justify-center max-h-[559px]">
        <div className="
          hidden 2xl:flex 
          flex-[0_0_306px]"
        >
          <StaticImage 
            src="../../images/contact-form-image-left.png" 
            className="h-[469px]"
            alt=""/>
        </div>
        <div className="flex md:flex-[0_0_681.85px] items-center justify-center rounded-lg bg-white">
            <ContactForm title="Message from Contact form" />
        </div>
        <div className="
          hidden 
          2xl:flex 
          flex-[0_0_306px]">
          <StaticImage
            src="../../images/contact-form-image-right.png"
            className="h-[469px]" 
            alt=""/>
        </div>
      </div>
    </>
  )
}

export default ContactFormSection
