import * as React from "react"
import { graphql } from "gatsby"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Story from "../components/story/story"
import Contacts from "../components/contact/contacts"
import Wrapper from "../components/layout/wrapper"
import ContactFormSection from "../components/contact/contactFormSection"

// Static files
import Line from "../images/line-contact.svg"
const LineData = (
  [Line, 'top-24', 'left-0', '', '']
)
 
export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "contact"}}}) {
    nodes {
      frontmatter {
        ctaHeading
        ctaSubheading
        metaDescription
        metaTitle
        templateKey
        intro {
          heading
          subheading
          text
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        contacts {
          email
          phone
        }
      }
    }
  }
}
` 

const IndexPage = ({ data }) => {
  const allData = data?.allMdx?.nodes[0]
  const allIntro = allData?.frontmatter?.intro 
  const allContacts = allData?.frontmatter?.contacts
  const Stories = () => (
    allIntro.map((item, index) => {
      return (
          <Story
            key={index}
            heading={item.heading}
            subheading={item.subheading}
            text={item.text}
            image={item.image}
            imageAlt={item.imageAlt}
            reverse={index % 2 ? false : true}
          />
      )
    })
  )
  return (
    <Layout line={LineData} fullWidth>
      <Seo 
        title={allData.frontmatter.metaTitle}
        description={allData?.frontmatter?.metaDescription}
      />
      <Wrapper>
        <div className="flex flex-col gap-y-32 my-16">
          <Stories/>
        </div>
      </Wrapper>
      <Contacts contacts={allContacts}/>
      <Wrapper>
        <ContactFormSection
          heading={allData.frontmatter.ctaHeading}
          subheading={allData.frontmatter.ctaSubheading}
        />
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
