import * as React from "react"

// Static files
import Email from "../../images/contact-email-icon.svg"
import Talk from "../../images/contact-talk-icon.svg"

const Contacts = ({ contacts }) => {
    return (
        <div className="
            w-full 
            bg-blue-darker 
            py-14
            "
        >
            <div className="
                mx-auto
                flex flex-col 
                justify-center
                max-w-screen-2xl px-6 sm:px-16
            ">
                <div className="
                    flex flex-col lg:flex-row
                    justify-around
                    my-16
                    gap-y-20
                    text-white
                ">
                    <a className="flex flex-row items-center gap-6 hover:scale-110 transition-transform hover:text-white" target="_blank" rel="noreferrer" href={`mailto:${contacts.email}`} title="E-mail">
                        <img className="w-[90px] sm:w-[164px]" src={Email} alt=""/>
                        <div className="flex flex-col gap-2">
                                <h3 className="text-xl sm:text-3xl">E-mail us:</h3>
                                <p className="text-base sm:text-xl underline">{contacts.email}</p>
                        </div>
                    </a>
                    <a className="flex flex-row items-center gap-6 hover:scale-110 transition-transform hover:text-white" target="_blank" rel="noreferrer" href={`tel:${contacts.phone}`} title="Phone">
                        <img className="w-[90px] sm:w-[164px]" src={Talk} alt=""/>
                        <div className="flex flex-col gap-2">
                                <h3 className="text-xl sm:text-3xl">Let's talk:</h3>
                                <p className="text-base sm:text-xl">{contacts.phone}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Contacts